import Controller from '@ember/controller';
import ENV from 'additive-voucher/config/environment';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { tracked } from '@glimmer/tracking';
import { task, timeout, all } from 'ember-concurrency';
import { bind } from '@ember/runloop';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';

export default class InstanceVouchersIndexVoucherEditController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service multimedia;
  @service router;
  @service store;
  @service uiAppSettings;
  @service uiToast;
  @service uiDialog;

  @tracked
  isMobile = false;

  @tracked
  isDiscardChangesDialog = false;

  @tracked
  isExitingRoute = false;

  @tracked
  isFormSubmitted = false;

  /* changesets */
  /**
   * Changeset which handles validations for all properties,
   * that are shared between the different voucher-types.
   *
   * @type {Object} generalChangeset
   * @default null
   */
  @tracked
  generalChangeset = null;

  /**
   * Changeset which handles type-specific amount-validations.
   * i.e. discount for service-voucher or min- max- validation for value-vouchers.
   * (WIP)
   *
   * @type {Object} detailChangeset
   * @default null
   */
  @tracked
  detailChangeset = null;

  @tracked
  baseChangeset = null;

  /**
   * Internal changeset, corresponds a merge of all subchangesets.
   *
   * @type {Object} _changeset
   * @default null
   * @private
   */
  @tracked
  _changeset = null;

  /**
   * Whether the changes are already discarded
   *
   * @type {Boolean} _isDiscarded
   * @default false
   * @private
   */
  @tracked
  _isDiscarded = false;

  get isReadOnly() {
    return this.currentUser?.isViewer;
  }

  get isProfessionalPlan() {
    return this.uiAppSettings?.isProfessionalPlan;
  }

  get showUnlockCode() {
    return this.isProfessionalPlan || this.detailChangeset?.get('isFree');
  }

  get isProductVoucher() {
    return this.model?.type === 'product';
  }
  get isServiceVoucher() {
    return this.model?.type === 'service';
  }
  get isStayVoucher() {
    return this.model?.type === 'stay';
  }
  get isTreatmentVoucher() {
    return this.model?.type === 'treatment';
  }
  get isValueVoucher() {
    return this.model?.type === 'value';
  }

  /**
   * true if the vouchers validityPeriod is set to custom
   * @computed hasCustomExpiration
   * @return {Boolean} true if custom
   */
  get hasCustomExpiration() {
    return this.baseChangeset.get('validityPeriod') === 'custom';
  }

  /**
   * true if the discount toggle is enabled
   * @computed isDiscounted
   * @return {Boolean} true if enabled
   */
  get isDiscounted() {
    return this.detailChangeset?.isDiscounted;
  }

  /**
   * The path to the organization in the content app
   * @computed contentPath
   * @return {String} the url to the content-app
   */
  get contentPath() {
    return `${ENV.APP.contentHost}/${this.currentUser?.currentOrganization?.id}`;
  }

  constructor() {
    super(...arguments);

    this.resizeListener = bind(this, this._checkWindow);
    // TODO: Listener cleanup when implementing mobile view
    window.addEventListener('resize', this.resizeListener, false);
  }

  /**
   * Merges all subchangesets for the given type and returns the new one.
   *
   * @function getChangesets
   * @param {String} type the type of the voucher
   * @return {Object} a merge of all subchangesets
   */
  getChangesets(/* type */) {
    const baseChangeset = this.baseChangeset;
    const detailChangeset = this.detailChangeset;

    return baseChangeset?.merge(detailChangeset);
  }

  discardChanges() {
    this.isDiscarded = true;

    // rollback changeset
    const _changeset = this.getChangesets();
    _changeset.rollback();

    // get the underlying model
    const voucherModel = _changeset.get('data');

    // remove empty model from store
    voucherModel.get('isNew') && voucherModel.rollbackAttributes();
    this.isDiscardChangesDialog = false;
    this.isDiscarded = false;
    this.router.transitionTo('instance.vouchers.index.voucher', _changeset.get('id'));
  }

  /**
   * Executes the differnet validations on the changesets, if the validations pass
   * the changesets get merged and a request to persitst the voucher gets sent to the api.
   *
   * @type {Task}
   * @function saveVoucher
   */
  @task(function* () {
    if (this.baseChangeset.get('_allInterestTags')) {
      const interests = [];
      const travelMotivations = [];
      const travelTimes = [];
      this.baseChangeset.get('_allInterestTags').map(function (e) {
        switch (e.type) {
          case 'interest':
            interests.push(e);
            break;
          case 'travel_time':
            travelTimes.push(e);
            break;
          case 'travel_motivation':
            travelMotivations.push(e);
            break;
        }
      });

      this.baseChangeset.set('interests', interests);
      this.baseChangeset.set('travelTimes', travelTimes);
      this.baseChangeset.set('travelMotivations', travelMotivations);
    }

    const baseChangeset = this.baseChangeset;
    const detailChangeset = this.detailChangeset;

    yield baseChangeset.validate();
    yield detailChangeset.validate();

    const presenceMessage = this.intl.t('errors.required');

    // Reset validityPeriodCustomDate, if the validity period is not set to "custom".
    if (baseChangeset.get('validityPeriod') !== 'custom') {
      baseChangeset.set('validityPeriodCustomDate', null);
    }

    // needs to be added after base validation or the validate functions clearsor the validate functions clears the error-stack
    if (this.hasCustomExpiration) {
      if (!baseChangeset.get('validityPeriodCustomDate')) {
        baseChangeset.addError('validityPeriodCustomDate', presenceMessage);
      } else if (
        isBefore(new Date(baseChangeset.get('validityPeriodCustomDate')), startOfDay(new Date()))
      ) {
        // if old validityPeriodCustomDate is passed
        const beforeMessage = this.intl.t('errors.beforeDate');
        baseChangeset.addError('validityPeriodCustomDate', beforeMessage);
      }
    }

    // validation check gets only applied if the `isDiscounted`-property is set to true
    if (this.isDiscounted) {
      const discountedAmount = detailChangeset.get('discountedAmount');
      const discountedPercentage = detailChangeset.get('discountedPercentage');
      const discountType = detailChangeset.get('discountType');
      // in case the amount is not set
      if (!discountedAmount && discountType === 'fixed') {
        detailChangeset.addError('discountedAmount', presenceMessage);
      } else if (Number(discountedAmount) >= Number(detailChangeset.get('amount'))) {
        // the discount needs top be lower than the amount
        detailChangeset.addError('discountedAmount', this.intl.t('errors.discountedAmount'));
      }

      // in case the percentage is not set
      if (!discountedPercentage && discountType === 'percent') {
        detailChangeset.addError('discountedPercentage', presenceMessage);
      } else if (Number(discountedPercentage) >= 100) {
        // the percentage needs to be lower than 100
        detailChangeset.addError(
          'discountedPercentage',
          this.intl.t('errors.discountedPercentage')
        );
      }
    }

    /**
     * Validations for gift vouchers. as we need a validation across both changesets
     * validation: `isLocked` must be true when voucher is a gift voucher
     */
    if (
      baseChangeset.get('type') === 'service' &&
      detailChangeset.get('isFree') &&
      !baseChangeset.get('isLocked')
    ) {
      baseChangeset.addError('isLocked', this.intl.t('errors.unlockCode.gift'));
    } else {
      /* Removes possible previously manually added error*/
      baseChangeset.validate('isLocked');
    }

    /**
     * Validations for treatment vouchers.
     * validation: 'treatmentCategories' must be valid
     */
    if (baseChangeset.get('type') === 'treatment') {
      let allTreatmentCategories = yield this.store.peekAll('treatment-category');
      if (!allTreatmentCategories || allTreatmentCategories.length === 0) {
        allTreatmentCategories = yield this.store.findAll('treatment-category');
      }

      const treatmentCategories = detailChangeset.get('treatmentCategories');
      // Filter valid treatment categories
      const validTreatments = allTreatmentCategories.filter(
        (treatment) => treatmentCategories.indexOf(treatment.id) >= 0
      );

      // Check if all treatment categories are valid
      if (validTreatments.length !== treatmentCategories.length) {
        let ids = [];

        validTreatments.forEach((treatment) => {
          const treatmentId = treatment.get('id');
          ids.push(treatmentId);
        });

        // Remove invalid treatment categories
        detailChangeset.set('treatmentCategories', ids);
      }
    }

    /**
     * Validations for product vouchers.
     * validation: 'productCategories' must be valid
     */
    if (baseChangeset.get('type') === 'product') {
      let allProductCategories = yield this.store.peekAll('product-category');
      if (!allProductCategories || allProductCategories.length === 0) {
        allProductCategories = yield this.store.findAll('product-category');
      }

      const productCategories = detailChangeset.get('productCategories');

      // Filter valid product categories
      const validProducts = allProductCategories.filter(
        (prodCategory) => productCategories.indexOf(prodCategory.id) >= 0
      );

      // Check if all product categories are valid
      if (validProducts.length !== productCategories.length) {
        let ids = [];

        validProducts.forEach((product) => {
          const prodCategoryId = product.get('id');
          ids.push(prodCategoryId);
        });

        // Remove invalid product categories
        detailChangeset.set('productCategories', ids);
      }
    }

    /**
     * Validations for stay vouchers.
     * validation: 'roomTypes' must be valid
     */
    if (baseChangeset.get('type') === 'stay') {
      let allRoomTypes = yield this.store.peekAll('room-type');
      if (!allRoomTypes || allRoomTypes.length === 0) {
        allRoomTypes = yield this.store.findAll('room-type');
      }

      const roomTypes = detailChangeset.get('roomTypes');
      // Filter valid room types
      const validRooms = allRoomTypes.filter((roomType) => roomTypes.indexOf(roomType.id) >= 0);

      // Check if all room-types are valid
      if (validRooms.length !== roomTypes.length) {
        let ids = [];

        validRooms.forEach((room) => {
          const roomId = room.get('id');
          ids.push(roomId);
        });

        // Remove invalid room-types
        detailChangeset.set('roomTypes', ids);
      }
    }

    // merge changesets to one
    const _changeset = baseChangeset.merge(detailChangeset);

    this._changeset = _changeset;
    this.isFormSubmitted = true;

    if (_changeset.get('isValid')) {
      try {
        let tasks = [];
        // save voucher and keep the task running for at least 250ms
        tasks.push(_changeset.save());
        tasks.push(timeout(250));

        yield all(tasks);

        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });

        this.isExitingRoute = true;

        this.discardChanges();
      } catch (error) {
        // Show error message if necessary translations of treatments are missing
        if (baseChangeset.get('type') === 'treatment') {
          if (error?.errors?.length > 0 && error?.errors[0]?.title === 'Invalid Attribute') {
            this.uiDialog.showError(
              this.intl.t('vouchers.voucher.properties.errors.not_exists.title'),
              this.intl.t('vouchers.voucher.properties.errors.not_exists.description')
            );
            return;
          }
        }
        // Show error message if necessary translations of selected room-type are missing
        if (baseChangeset.get('type') === 'stay') {
          if (error?.errors?.length > 0 && error?.errors[0]?.title === 'Invalid Attribute') {
            this.uiDialog.showConfirm(
              this.intl.t('vouchers.voucher.properties.errors.roomTypeTranslationsMissing.title'),
              this.intl.t(
                'vouchers.voucher.properties.errors.roomTypeTranslationsMissing.description'
              ),
              () => {
                window.open(`${this.contentPath}/rooms`, '_blank');
              },
              this.intl.t(
                'vouchers.voucher.properties.errors.roomTypeTranslationsMissing.buttonText'
              ),
              true,
              true
            );
          }
        }

        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
  })
  saveVoucher;

  /**
   * @tracked
   * Checks the current windowsize programmatically and sets the `isMobile`-prop
   * to true if the current window size is lower than `600px`.
   *
   * @function _checkWindow
   * @private
   * @return {void}
   */
  _checkWindow() {
    this.isMobile = !window.matchMedia('(min-width: 600px)').matches;
  }

  // TODO: Move clean-up logic from route here after we implemented all different voucher types
  cleanUp() {}

  @action
  backAction() {
    const _changeset = this.getChangesets();

    // leave view if no changes have been made
    if (!_changeset.get('isPristine')) {
      this.isDiscardChangesDialog = true;
      return;
    }

    this.router.transitionTo('instance.vouchers.index.voucher', _changeset.get('id'));
  }

  @action
  confirmDiscard() {
    this.discardChanges();
  }

  @action
  openMultimedia() {
    this.router.transitionTo('instance.vouchers.index.voucher.edit.additive-multimedia-engine');
  }

  @action
  closeMultimedia() {
    this.router.transitionTo('instance.vouchers.index.voucher.edit.index');
  }

  @action
  addMedium(_, newImages) {
    const currentImages = this.baseChangeset.get('images') || [];
    const images = currentImages.concat(newImages);

    this.baseChangeset.set('images', images);
  }

  @action
  removeMedium({ mediumId }) {
    const images = this.baseChangeset
      .get('images')
      .filter((resource) => resource.mediumId !== mediumId);
    this.baseChangeset.set('images', images);
  }

  @action
  onResourceEdit(changedResource, resourceArrayIndex) {
    const images = this.baseChangeset.get('images');
    images.replace(resourceArrayIndex, 1, [changedResource]);
    this.baseChangeset.set('images', images);
  }
}
