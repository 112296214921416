import { or, alias, equal } from '@ember/object/computed';
import Controller from '@ember/controller';

import { set, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';

import { task, timeout, all } from 'ember-concurrency';
import { bind } from '@ember/runloop';

export default Controller.extend({
  authenticatedFetch: service(),
  intl: service(),
  multimedia: service(),
  router: service(),
  store: service(),
  uiAppSettings: service(),
  uiToast: service(),

  /**
   * vt stands for voucherType.
   *
   * The problem here is that we can't use type, as it's to generic and
   * already used for the filters-qp's
   */
  queryParams: ['vt'],
  vt: null,
  isMobile: false,
  isExitingRoute: false,
  isFormSubmitted: false,

  _currentVt: null,

  /* changesets */
  /**
   * Changeset which handles validations for all properties,
   * that are shared between the different voucher-types.
   *
   * @type {Object} generalChangeset
   * @default null
   */
  generalChangeset: null,

  /**
   * Changeset which handles type-specific amount-validations.
   * i.e. discount for service-voucher or min- max- validation for value-vouchers.
   * (WIP)
   *
   * @type {Object} detailChangeset
   * @default null
   */
  detailChangeset: null,

  baseChangeset: null,

  /**
   * Internal changeset, corresponds a merge of all subchangesets.
   *
   * @type {Object} _changeset
   * @default null
   * @private
   */
  _changeset: null,

  /**
   * Whether the changes are already discarded
   *
   * @type {Boolean} _isDiscarded
   * @default false
   * @private
   */
  _isDiscarded: false,

  isReadOnly: or('saveVoucher.isRunning', '_changeset.isValidating', 'isExitingRoute'),

  isProductVoucher: equal('vt', 'product'),
  isServiceVoucher: equal('vt', 'service'),
  isStayVoucher: equal('vt', 'stay'),
  isTreatmentVoucher: equal('vt', 'treatment'),
  isValueVoucher: equal('vt', 'value'),

  /**
   * true if the vouchers validityPeriod is set to custom
   * @computed hasCustomExpiration
   * @return {Boolean} true if custom
   */
  hasCustomExpiration: equal('baseChangeset.validityPeriod', 'custom'),

  /**
   * true if the discount toggle is enabled
   * @computed isDiscounted
   * @return {Boolean} true if enabled
   */
  isDiscounted: alias('detailChangeset.isDiscounted'),

  isProfessionalPlan: alias('uiAppSettings.isProfessionalPlan'),
  showUnlockCode: or('isProfessionalPlan', 'detailChangeset.isFree'),

  init() {
    this._super(...arguments);
    this.resizeListener = bind(this, this._checkWindow);
    // TODO: Listener cleanup when implementing mobile view
    window.addEventListener('resize', this.resizeListener, false);
  },

  /**
   * Merges all subchangesets for the given type and returns the new one.
   *
   * @function getChangesets
   * @param {String} type the type of the voucher
   * @return {Object} a merge of all subchangesets
   */
  getChangesets(/* type */) {
    const baseChangeset = this.baseChangeset;
    const detailChangeset = this.detailChangeset;
    return baseChangeset?.merge(detailChangeset);
  },

  discardChanges() {
    // rollback changeset
    set(this, 'isDiscarded', true);
    const _changeset = this.getChangesets();
    _changeset.rollback();
    // get the underlying model
    const voucherModel = _changeset.get('data');
    // remove empty model from store
    voucherModel.get('isNew') && voucherModel.rollbackAttributes();
    set(this, 'isDiscardChangesDialog', false);
    this.router.transitionTo('instance.vouchers');
    set(this, 'isDiscarded', false);
  },

  /**
   * Executes the differnet validations on the changesets, if the validations pass
   * the changesets get merged and a request to persitst the voucher gets sent to the api.
   *
   * @type {Task}
   * @function saveVoucher
   */
  saveVoucher: task(function* () {
    try {
      if (this.baseChangeset.get('_allInterestTags')) {
        const interests = [];
        const travelMotivations = [];
        const travelTimes = [];
        this.baseChangeset.get('_allInterestTags').map(function (e) {
          switch (e.type) {
            case 'interest':
              interests.push(e);
              break;
            case 'travel_time':
              travelTimes.push(e);
              break;
            case 'travel_motivation':
              travelMotivations.push(e);
              break;
          }
        });

        this.baseChangeset.set('interests', interests);
        this.baseChangeset.set('travelTimes', travelTimes);
        this.baseChangeset.set('travelMotivations', travelMotivations);
      }

      const baseChangeset = this.baseChangeset;
      const detailChangeset = this.detailChangeset;

      yield baseChangeset.validate();
      yield detailChangeset.validate();

      const presenceMessage = this.intl.t('errors.required');

      if (this.hasCustomExpiration && !baseChangeset.get('validityPeriodCustomDate')) {
        // needs to be added after base validation or the validate functions clears the error-stack
        baseChangeset.addError('validityPeriodCustomDate', presenceMessage);
      }

      const discountType = detailChangeset.get('discountType');

      // validation check gets only applied if the `isDiscounted`-property is set to true
      if (this.isDiscounted && discountType === 'fixed') {
        const discountedAmount = detailChangeset.get('discountedAmount');
        // in case the amount is not set
        if (!discountedAmount) {
          detailChangeset.addError('discountedAmount', presenceMessage);
        } else if (Number(discountedAmount) >= Number(detailChangeset.get('amount'))) {
          // the discount needs top be lower than the amount
          detailChangeset.addError('discountedAmount', this.intl.t('errors.discountedAmount'));
        }
      }
      if (this.isDiscounted && discountType === 'percent') {
        const discountedPercentage = detailChangeset.get('discountedPercentage');
        // in case the amount is not set
        if (!discountedPercentage) {
          detailChangeset.addError('discountedPercentage', presenceMessage);
        } else if (Number(discountedPercentage) >= 100) {
          // the discount percentage needs top be lower than 100
          detailChangeset.addError(
            'discountedPercentage',
            this.intl.t('errors.discountedPercentage')
          );
        }
      }

      /**
       * Validations for gift vouchers. as we need a validation across both changesets
       * validation: `isLocked` must be true when voucher is a gift voucher
       */
      if (
        baseChangeset.get('type') === 'service' &&
        detailChangeset.get('isFree') &&
        !baseChangeset.get('isLocked')
      ) {
        baseChangeset.addError('isLocked', this.intl.t('errors.unlockCode.gift'));
      } else {
        /* Removes possible previously manually added error*/
        baseChangeset.validate('isLocked');
      }

      // merge changesets to one
      const _changeset = baseChangeset.merge(detailChangeset);

      set(this, '_changeset', _changeset);
      set(this, 'isFormSubmitted', true);

      if (_changeset.get('isValid')) {
        let tasks = [];

        // save voucher and keep the task running for at least 250ms
        tasks.push(_changeset.save());
        tasks.push(timeout(250));

        yield all(tasks);
        set(this, 'isExitingRoute', true);

        this.router.transitionTo('instance.vouchers.index.voucher', _changeset.get('id'));

        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }),

  /**
   * Checks the current windowsize programmatically and sets the `isMobile`-prop
   * to true if the current window size is lower than `600px`.
   *
   * @function _checkWindow
   * @private
   * @return {void}
   */
  _checkWindow() {
    set(this, 'isMobile', !window.matchMedia('(min-width: 600px)').matches);
  },

  // TODO: Move clean-up logic from route here after we implemented all different voucher types
  cleanUp() {},

  actions: {
    backAction() {
      const _changeset = this.getChangesets();

      if (!_changeset.get('isPristine')) {
        set(this, 'isDiscardChangesDialog', true);
        return;
      }
      this.router.transitionTo('instance.vouchers');
    },
    confirmDiscard() {
      this.discardChanges();
    },
    openMultimedia() {
      /* Backup and remove voucher type query param before routing into engine */
      setProperties(this, {
        _currentVt: this.vt,
        vt: null
      });
      this.router.transitionTo('instance.vouchers.index.create.additive-multimedia-engine');
    },
    closeMultimedia() {
      /* Re-set voucher type query param */
      this.router.transitionTo('instance.vouchers.index.create.index', {
        queryParams: { vt: this._currentVt }
      });
    },
    addMedium(_, newImages) {
      const currentImages = this.baseChangeset.get('images') || [];
      const images = currentImages.concat(newImages);
      const orderedImages = images.map((resource, index) => {
        return Object.assign({ position: index + 1 }, { ...resource });
      });
      this.baseChangeset.set('images', orderedImages);
    },
    removeMedium({ mediumId }) {
      const images = this.baseChangeset
        .get('images')
        .filter((resource) => resource.mediumId !== mediumId);
      this.baseChangeset.set('images', images);
    },
    onResourceEdit(changedResource, resourceArrayIndex) {
      const images = this.baseChangeset.get('images');
      images.replace(resourceArrayIndex, 1, [changedResource]);
      this.baseChangeset.set('images', images);
    }
  }
});
